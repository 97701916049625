/*
 * @Description:
 * @LastEditTime: 2022-12-29 11:00:22
 * @LastEditors: 何俊峰
 * @Author: 何俊峰
 * @Date: 2022-12-29 10:10:25
 */
import Vue from "vue";
import App from "./App.vue";
import htmlToPdf from "@/utils/htmlToPdf";
import "amfe-flexible";
import moment from "moment";
import router from './router/index'

Vue.prototype.$moment = moment;
moment.locale("zh-cn"); // 汉化
Vue.use(htmlToPdf);
Vue.config.productionTip = false;

Vue.prototype.colorText= function (txt) {  
	txt = txt.replace('网络级', '<span class="color-网络级">网络级</span>')
  .replace('初始级', '<span class="color-初始级">初始级</span>')
  .replace('单元级', '<span class="color-单元级">单元级</span>')
  .replace('流程级', '<span class="color-流程级">流程级</span>')
  .replace('生态级', '<span class="color-生态级">生态级</span>')

	return txt
};
let app = new Vue({
  router,
  render: (h) => h(App),
})
app.$mount("#app");
