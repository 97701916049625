/*
 * @Description:
 * @LastEditTime: 2023-01-05 16:32:11
 * @LastEditors: 何俊峰
 * @Author: 何俊峰
 * @Date: 2022-12-29 10:22:11
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = [
  {
    path: '',
    component: () => import('@/components/pdf.vue'),
  },
  {
    path: '/report',
    component: () => import('@/components/report.vue'),
  },
]

const router = new Router({
  mode: "history",
  routes
})

export default router;