<!--
 * @Description:
 * @LastEditTime: 2023-01-09 17:50:45
 * @LastEditors: 刘仁秀
 * @Author: 何俊峰
 * @Date: 2022-12-29 10:10:25
-->
<template>
  <div id="app">
    <!-- <Pdf /> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Pdf from "@/components/pdf";

export default {
  name: 'App',
  components: {
    // Pdf,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}

.textIndent {
  text-indent: 1em;
}

.bold {
  font-weight: bold;
}

.color-primary {
  color: #3d5bfd!important;
  font-weight: bold;
}

.color-初始级 {
  color: #5470c6;
  font-weight: bold;
}

.color-单元级 {
  color: #91cc75;
  font-weight: bold;
}

.color-流程级 {
  color: #fac858;
  font-weight: bold;
}

.color-网络级 {
  color: #ee6666;
  font-weight: bold;
}

.color-生态级 {
  color: #cccccc;
  font-weight: bold;
}
</style>
